import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getUserId, getRoleId, isSuperAdmin, isCompanyAdmin } from '../../auth/auth.js'; 
import {extraListItems, LmsListItems, ManageListItems } from './mainListItems.js';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      height: '100vh', 
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp, 
          duration: theme.transitions.duration.leavingScreen,
        }), 
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);
 
export default function NavBar({open, handleDrawerClose}) {
  const [hasChatAccess, setHasChatAccess] = React.useState(false);
  const [openManage, setOpenManage] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  React.useEffect(() => {
    const ALLOWED_CHAT_USER_IDS = [1, 2, 72];
    const userId = getUserId();
    setHasChatAccess(ALLOWED_CHAT_USER_IDS.includes(Number(userId)));
    console.log(getRoleId());
    console.log(isSuperAdmin(getRoleId()));
    console.log(isCompanyAdmin(getRoleId()));
    if(isSuperAdmin(getRoleId()) || isCompanyAdmin(getRoleId())) {
      setIsAdmin(true);
    }
  }, []);

  const handleClick = (menu) => {
    switch(menu) {
      case 'Manage':
        setOpenManage(!openManage);
        break;
      default:
        break;
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        
      </Toolbar>
      <Divider />
      <List component="nav">
        <LmsListItems navBarOpen={open} /> 
        {isAdmin && <Divider sx={{ my: 1 }} />}
        {isAdmin && <ManageListItems handleClick={handleClick} openManage={openManage} navBarOpen={open} />}
      </List>
      <Divider />
    </Drawer>
  );
} 

import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { ToastContainer, toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import sortData from '../common/sortColumns.js';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';

function ManageAdmins() {
  const [userData, setUserData] = React.useState([]); 
  const [farms, setFarms] = React.useState([]); // array to hold farm data from API
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [editingId, setEditingId] = React.useState(null);
  const [originalData, setOriginalData] = React.useState({});
  const [tempData, setTempData] = React.useState({});
  const [sortField, setSortField] = React.useState('');

  const [sortDirection, setSortDirection] = React.useState({
    last_name: 'desc',
    email: 'desc',
    client_name: 'desc'
  }); 

  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(5);

  const [clients, setClients] = React.useState([]);
  const isSuperAdmin = getRoleId() === 1;
  const currentClientId = getClientId();

  const sortedUserData = sortData(userData, sortField, sortDirection[sortField]);

  const navigate = useNavigate();
  const token = getToken(); 

  const roleId = getRoleId();
  const clientId = getClientId();

  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/readclients`, {
          headers: {
            'Authorization': `Bearer ${getToken()}`
          }
        });
        const data = await response.json();
        if (Array.isArray(data)) {
          setClients(data);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    if (isSuperAdmin) {
      fetchClients();
    }
  }, [isSuperAdmin]);

  const fetchUsers = () => {
    console.log('Fetching users...'); // Debug log
    fetch(`${BASE_URL}/api/readadmins`, { 
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); 
    })
    .then(data => {
      console.log('Received data:', data); // Debug log
      if (Array.isArray(data)) {
        const usersWithFarmData = data.map(user => ({
          ...user,
          farm_name: farms.find(farm => farm.id === user.farm_id)?.name || 'N/A',
          farm_region: farms.find(farm => farm.id === user.farm_id)?.region || 'N/A',
        }));
        console.log('Processed data:', usersWithFarmData); // Debug log
        setUserData(usersWithFarmData);
      } else {
        console.error('Data is not an array:', data);
      }
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });
  }

  React.useEffect(() => {
    console.log('Component mounted'); // Debug log
    fetchUsers();   
  }, []); // fetch users on component mount

  React.useEffect(() => {
    if (farms.length > 0) {
      fetchUsers();
    }
  }, [farms]);

  const deleteUser = (id) => { 
    setSelectedId(id); 
    setOpenDialog(true); 
  }

  const handleConfirmDelete = () => {
    setOpenDialog(false);
  
    fetch(`${BASE_URL}/api/deleteuser/${selectedId}`, { 
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } else {
        toast.success('User successfully deleted!', {
          autoClose: 1000, // Toast notification will close in 1 second
        });
        // Refresh the data
        return fetchUsers(); 
      }
    })
    .catch(error => {
      console.error('Error:', error);
      toast.error('An error occurred while trying to delete the user');
    });
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const editUser = (id) => {
    const rowData = userData.find(user => user.user_id === id);
    if (rowData) {
      setEditingId(id);
      setTempData({
        first_name: rowData.first_name || '',
        last_name: rowData.last_name || '',
        email: rowData.email || '',
        role_id: rowData.role_id,
        client_id: rowData.client_id
      });
      setOriginalData(rowData);
    }
  };

  const cancelEditing = () => {
    setEditingId(null);
    setTempData({});
  };

  React.useEffect(() => {
    if (editingId !== null) {
      const rowData = userData.find(user => user.user_id === editingId);
      setOriginalData(rowData || {});
      setTempData(rowData || {});
    }
  }, [editingId, userData]);

  const handleSave = async (id) => {
    try {
      console.log('Saving data:', tempData); // Debug log

      const response = await fetch(`${BASE_URL}/api/updateadmin/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        },
        body: JSON.stringify({
          first_name: tempData.first_name,
          last_name: tempData.last_name,
          email: tempData.email,
          role_id: tempData.role_id,
          client_id: tempData.client_id
        })
      });

      const data = await response.json();
      console.log('Response:', data); // Debug log

      if (!response.ok) {
        throw new Error(data.error || 'Failed to update user');
      }

      // After successful save, check if user updated their own role/client
      const currentUserId = getUserId();
      if (String(id) === String(currentUserId)) {
        // If we received a new token, update it in storage
        if (data.newToken) {
          const storage = localStorage.getItem('token') ? localStorage : sessionStorage;
          storage.setItem('token', data.newToken);
          // Force a page refresh to update all states
          window.location.href = '/';
        }
      } else {
        // Just refresh the users list
        fetchUsers();
      }

      setEditingId(null);
      setTempData({});
      toast.success('User updated successfully!');

    } catch (error) {
      console.error('Error saving user:', error);
      toast.error(error.message || 'Failed to update user');
    }
  };

  const handleAddUser = () => {
    navigate('/adduser');
  };

  const handleSort = (field) => {
    // Toggle the sort direction for the specific field
    const newDirection = sortDirection[field] === 'asc' ? 'desc' : 'asc';
  
    // Update the sort directions state
    setSortDirection({
      ...sortDirection,
      [field]: newDirection
    });
  
    // Set the sort field
    setSortField(field);
  };

  // Get current page data
  const paginatedData = sortedUserData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Add this helper function at the top of the component
  const getRoleDisplay = (roleId) => {
    switch (roleId) {
      case 1:
        return 'Super Admin';
      case 2:
        return 'Admin';
      case 3:
        return 'User';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        minHeight: '100vh',
        overflow: 'auto',
      }}
    
    >
      <CssBaseline />
      <ToastContainer position="top-center" />
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>

              <Box sx={{
                display: 'flex',
                justifyContent: 'center', 
              }}>
                <Typography variant="h6" component="h2" gutterBottom>
                  Manage Admins
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ maxHeight: '80vh', overflow: 'auto' }}>

                <Table aria-label="user table" stickyHeader>

                  <TableHead>
                    <TableRow>
                      {/* Name */}
                      <TableCell 
                        onClick={() => handleSort('last_name')}
                        sx={{ color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer' }}
                      >
                        Name {sortDirection['last_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Email */}
                      <TableCell
                        onClick={() => handleSort('email')}
                        sx={{ color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer' }}
                      >
                        E-Mail {sortDirection['email'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                      </TableCell>

                      {/* Client Name - Only visible to Super Admin */}
                      {roleId === 1 && (
                        <TableCell
                          onClick={() => handleSort('client_name')}
                          sx={{ color: 'rgba(0, 0, 0, 0.87)', cursor: 'pointer' }}
                        >
                          Client {sortDirection['client_name'] === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </TableCell>
                      )}

                      <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Role</TableCell>
                      <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Edit</TableCell>
                      <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((row, index) => (
                        <TableRow 
                          key={row.user_id || index}
                          sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                        >
                          {/* Name */}
                          <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {editingId === row.user_id ? (
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                  <input 
                                      value={tempData?.first_name ?? row.first_name} 
                                      onChange={e => setTempData({...tempData, first_name: e.target.value})}
                                      placeholder="First Name"
                                      style={{ 
                                          padding: '8px',
                                          border: '1px solid #ccc',
                                          borderRadius: '4px',
                                          width: '120px'
                                      }}
                                  />
                                  <input 
                                      value={tempData?.last_name ?? row.last_name} 
                                      onChange={e => setTempData({...tempData, last_name: e.target.value})}
                                      placeholder="Last Name"
                                      style={{ 
                                          padding: '8px',
                                          border: '1px solid #ccc',
                                          borderRadius: '4px',
                                          width: '120px'
                                      }}
                                  />
                              </Box>
                            ) : (
                              `${row.first_name} ${row.last_name}`
                            )}
                          </TableCell>

                          {/* Email */}
                          <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {editingId === row.user_id ? (
                              <input 
                                  value={tempData?.email ?? row.email} 
                                  onChange={e => setTempData({...tempData, email: e.target.value})}
                                  style={{ 
                                      padding: '8px',
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      width: '100%',
                                      height: '36px',
                                      boxSizing: 'border-box'
                                  }}
                              />
                            ) : (
                              row.email
                            )}
                          </TableCell>

                          {/* Client */}
                          <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {editingId === row.user_id ? (
                              <FormControl fullWidth>
                                <Select
                                  value={tempData?.client_id || row.client_id}
                                  onChange={e => setTempData({...tempData, client_id: e.target.value})}
                                  sx={{ 
                                    minWidth: '120px',
                                    height: '36px'
                                  }}
                                  size="small"
                                >
                                  {clients.map(client => (
                                    <MenuItem key={client.client_id} value={client.client_id}>
                                      {client.client_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              row.client_name || 'N/A'
                            )}
                          </TableCell>

                          {/* Role */}
                          <TableCell sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {editingId === row.user_id ? (
                              <FormControl fullWidth>
                                <Select
                                  value={tempData?.role_id ?? row.role_id}
                                  onChange={e => setTempData({...tempData, role_id: e.target.value})}
                                  sx={{ 
                                    minWidth: '120px',
                                    height: '36px'
                                  }}
                                  size="small"
                                >
                                  {isSuperAdmin && <MenuItem value={1}>Super Admin</MenuItem>}
                                  <MenuItem value={2}>Admin</MenuItem>
                                  <MenuItem value={3}>User</MenuItem>
                                </Select>
                              </FormControl>
                            ) : (
                              getRoleDisplay(row.role_id)
                            )}
                          </TableCell>

                          {/* Edit/Save/Cancel */}
                          <TableCell>
                            {editingId === row.user_id ? (
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button onClick={() => handleSave(row.user_id)} color="primary">
                                  Save
                                </Button>
                                <Button onClick={() => cancelEditing()} color="secondary">
                                  Cancel
                                </Button>
                              </Box>
                            ) : (
                              <IconButton 
                                aria-label="edit" 
                                onClick={() => editUser(row.user_id)}
                                sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </TableCell>

                          {/* Delete */}
                          <TableCell>  
                            <IconButton 
                              aria-label="delete" 
                              onClick={() => deleteUser(row.user_id)}
                              sx={{ color: 'rgba(0, 0, 0, 0.54)' }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No users found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                </Table>

                <TablePagination
                  rowsPerPageOptions={[5]}
                  component="div"
                  count={sortedUserData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />

              </TableContainer>

              {/* Add Users button - only visible to super admins and admins */}
              {(roleId === 1 || roleId === 2) && (
                <Box margin="1em" display="flex" justifyContent="flex-end">
                  <Button 
                    variant="contained" 
                    onClick={handleAddUser} 
                  >
                    Add User
                  </Button>
                </Box>
              )}

          </Grid>
        </Grid>
      </Container>

      <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      >
        <DialogTitle>{"Delete user"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageAdmins;
import React, { useState, useEffect } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    OutlinedInput,
} from '@mui/material';
import { BASE_URL } from '../../Constants.js';
import { getToken, getUserId, getRoleId, getClientId } from '../../auth/auth.js';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';

function LMSAddCourse() {
    const [courseName, setCourseName] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [editingCourseId, setEditingCourseId] = useState(null);
    const [courses, setCourses] = useState([]);
    const [page, setPage] = useState(1);
    const coursesPerPage = 3;
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');
    const [clients, setClients] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const isSuperAdmin = getRoleId() === 1;
    const currentClientId = getClientId();

    const fetchCourses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/courses`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch courses');
            const data = await response.json();
            setCourses(data);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setError('Failed to fetch courses');
        }
    };

    const handleEdit = (course) => {
        setEditingCourseId(course.id);
        setCourseName(course.course_name);
        setCourseDescription(course.course_description || '');
        
        // Ensure access_roles and access_clients are arrays
        const roles = Array.isArray(course.access_roles) 
            ? course.access_roles 
            : course.access_roles 
                ? [Number(course.access_roles)] 
                : [];
                
        const clients = Array.isArray(course.access_clients) 
            ? course.access_clients 
            : course.access_clients 
                ? [Number(course.access_clients)] 
                : [];
        
        setSelectedRoles(roles);
        setSelectedClients(clients);
    };

    const handleDelete = async (courseId) => {
        if (!window.confirm('Are you sure you want to delete this course?')) return;

        try {
            const response = await fetch(`${BASE_URL}/api/courses/${courseId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete course');
            
            setSuccess(true);
            setSuccessMessage('Course deleted successfully!');
            fetchCourses();
        } catch (error) {
            console.error('Error deleting course:', error);
            setError('Failed to delete course');
        }
    };

    const handleRoleChange = (event) => {
        setSelectedRoles(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClients(event.target.value);
    };

    const handleCourseSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess(false);

        if (!courseName.trim() || !courseDescription.trim()) {
            setError('Please fill in all fields');
            setIsLoading(false);
            return;
        }

        // Validate role and client selection
        if (selectedRoles.length === 0) {
            setError('Please select at least one role');
            setIsLoading(false);
            return;
        }

        if (isSuperAdmin && selectedClients.length === 0) {
            setError('Please select at least one client');
            setIsLoading(false);
            return;
        }

        try {
            const url = editingCourseId 
                ? `${BASE_URL}/api/courses/${editingCourseId}`
                : `${BASE_URL}/api/submitcourses`;

            const requestBody = {
                course_name: courseName.trim(),
                course_description: courseDescription.trim(),
                who_created: getUserId(),
                date_created: new Date().toISOString().split('T')[0],
                access_control: {
                    roles: selectedRoles,
                    clients: isSuperAdmin ? selectedClients : [currentClientId]
                }
            };

            const response = await fetch(url, {
                method: editingCourseId ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save course');
            }

            setSuccess(true);
            setSuccessMessage(editingCourseId ? 'Course updated successfully!' : 'Course added successfully!');
            
            // Reset form
            setCourseName('');
            setCourseDescription('');
            setSelectedRoles([]);
            setSelectedClients([]);
            setEditingCourseId(null);
            
            // Refresh the courses list
            fetchCourses();
        } catch (error) {
            console.error('Error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    useEffect(() => {
        if (isSuperAdmin) {
            fetch(`${BASE_URL}/api/readclients`, {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setClients(data);
                }
            })
            .catch(error => console.error('Error fetching clients:', error));
        }
    }, [isSuperAdmin]);

    const buttonText = isLoading 
        ? 'Processing...' 
        : editingCourseId 
            ? 'Update Course' 
            : 'Add Course';

    const formTitle = editingCourseId ? 'Edit Course' : 'Add New Course';

    const handleCancelEdit = () => {
        setEditingCourseId(null);
        setCourseName('');
        setCourseDescription('');
        setSelectedRoles([]);
        setSelectedClients([]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const indexOfLastCourse = page * coursesPerPage;
    const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
    const currentCourses = courses.slice(indexOfFirstCourse, indexOfLastCourse);

    const renderRoles = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip 
                        key={value} 
                        label={value === 1 ? 'Super Admin' : value === 2 ? 'Admin' : 'User'} 
                    />
                ))}
            </Box>
        );
    };

    const renderClients = (selected) => {
        if (!Array.isArray(selected)) {
            selected = [];
        }
        return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((clientId) => (
                    <Chip 
                        key={clientId} 
                        label={clients.find(c => c.client_id === clientId)?.client_name || clientId} 
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box 
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4, mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        {formTitle}
                    </Typography>

                    <Box component="form" onSubmit={handleCourseSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseName"
                            label="Course Name"
                            name="courseName"
                            autoFocus
                            value={courseName}
                            onChange={(e) => setCourseName(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="courseDescription"
                            label="Course Description"
                            name="courseDescription"
                            multiline
                            rows={4}
                            value={courseDescription}
                            onChange={(e) => setCourseDescription(e.target.value)}
                        />

                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="role-access-label">Role Access</InputLabel>
                            <Select
                                labelId="role-access-label"
                                multiple
                                value={selectedRoles || []}
                                onChange={handleRoleChange}
                                input={<OutlinedInput label="Role Access" />}
                                renderValue={renderRoles}
                            >
                                {isSuperAdmin && (
                                    <MenuItem value={1}>Super Admin</MenuItem>
                                )}
                                <MenuItem value={2}>Admin</MenuItem>
                                <MenuItem value={3}>User</MenuItem>
                            </Select>
                        </FormControl>

                        {isSuperAdmin && (
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="client-access-label">Client Access</InputLabel>
                                <Select
                                    labelId="client-access-label"
                                    multiple
                                    value={selectedClients || []}
                                    onChange={handleClientChange}
                                    input={<OutlinedInput label="Client Access" />}
                                    renderValue={renderClients}
                                >
                                    {clients.map((client) => (
                                        <MenuItem key={client.client_id} value={client.client_id}>
                                            {client.client_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={isLoading}
                            >
                                {buttonText}
                            </Button>
                            
                            {editingCourseId && (
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={handleCancelEdit}
                                    disabled={isLoading}
                                >
                                    Cancel Edit
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>

                <Paper sx={{ p: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Existing Courses
                    </Typography>
                    {currentCourses.map(course => (
                        <Box 
                            key={course.id} 
                            sx={{ 
                                mb: 3, 
                                p: 3, 
                                border: '1px solid #ddd', 
                                borderRadius: 2,
                                backgroundColor: 'white'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {course.course_name}
                            </Typography>
                            <Typography 
                                variant="body1" 
                                color="text.secondary" 
                                sx={{ mb: 2 }}
                            >
                                {course.course_description}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button 
                                    onClick={() => handleEdit(course)} 
                                    variant="outlined" 
                                    startIcon={<EditIcon />}
                                    disabled={isLoading}
                                >
                                    Edit
                                </Button>
                                <Button 
                                    onClick={() => handleDelete(course.id)} 
                                    variant="outlined" 
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    disabled={isLoading}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    ))}
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <Pagination 
                            count={Math.ceil(courses.length / coursesPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Paper>
            </Container>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LMSAddCourse;
